const delky=[2,		10,	10,	3, 3, 2,7,3, 3, 3, 2, 2,
    5,				2,		4,
    5	,				2,		4,
    4,				4,				10,				10,
    8, 6,6, 1, 1]

export default {


kilowaty: [6, 	12,	18,25,37,	55, 75, 	110,	150,	220,	300,	400,	550,	750,
        1100,	1500,	1850,	2200,	3000,3700, 4500, 5500,	7500,	9000,
        11000,	13200,
        16000,
        20000,
        25000,
        31500,
        35500,
        40000,
        50000,
        90000,
        110000
    ],

    volty:[  100,
        105,
        110,
        115,
        120,
        127,
        190,
        200,
        208,
        216,
        220,
        230,
        240,
        250,
        346,
        380,
        400,
        415,
        416,
        440,
        480,
        500,
        600,
        690],

    nucka: {
        '6200': [6202, 6203, 6204, 6205, 6206, 6207, 6208, 6209, 6210, 6211, 6212, 6213, 6214, 6215],
        '6300': [6302, 6303, 6304, 6305, 6306, 6307, 6308, 6309, 6310, 6311, 6312, 6313, 6314, 6315, 6316, 6317, 6318, 6319, 6320, 6321, 6322, 6324],
        'NU200': ['NU210', 'NU211', 'NU212', 'NU213', 'NU214', 'NU215'],
        'NU300': ['NU310', 'NU311', 'NU312', 'NU313', 'NU314', 'NU315', 'NU316', 'NU317', 'NU318', 'NU319', 'NU320', 'NU321', 'NU322', 'NU324']
    },

    windingProtections:[
        'no',
        '1*PTC',
        '3*PTC',
        '6*PTC',
        '1*PTO',
        '3*PTO',
        '6*PTO',
        '1*PT100',
        '3*PT100',
        '6*PT100'
    ],

    codeAsString:function(qr){
        if (!qr || qr==undefined) return ""
        var s=''
        for(let i=0; i<delky.length; i++){
            //console.log('dodo', i,this.qr[i] , delky[i], doplnit(this.qr[i], delky[i]))
            s+= this.doplnit(qr[i], delky[i])
        }
        return s
    },

    doplnit(str, delka){
        if (str===undefined) str=''
        str= `${str}`
        let s=""
        let dd=delka - str.length
        for(let j=0; j< dd; j++){
            s+= '_';
        }
        return s+str
    },




}
