<template>
  <div class="mrizkobunka" :class="focus + ` ro_${ro}`" >

    <span v-for="i in maxlength" :key="i" class="falesna-hodnota" :style="`left:${(i-1)*22 }px`">
      {{prvek(i-1)}}
    </span>
    <input type="text" v-bind:maxlength="maxlength" :readonly="ro"
           v-bind:value="value"
           v-on:input="$emit('input', $event.target.value)" v-on:focus="focus='focus'" v-on:focusout="focus=''"/>
  </div>
</template>

<script>

const doplnit = function (str, delka){
  var s=""
  if (!str) str="";

  for(var j=0; j< delka-str.length; j++){
    s= "_"+s;
  }
  return s+str
}

export default {
  name: "mrizkobunka",
  props: ['value', 'maxlength', 'ro'],

  data(){
    return {
      v:'1234567890',
      focus:''
    }
  },
  methods:{
    prvek(x){
      var s = doplnit(this.value,this.maxlength);
      return s[x]
    }
  }
}
</script>

<style scoped lang="scss">
  .mrizkobunka{
    margin:0 0 0 0px;
    padding:0;

   // background: white;



    &.focus span {  background-color: #ffffde; }


    input {
      width: 103%;
      font-family: Courier, "Courier New";
      font-size: 22px;
      padding: 1px 0px 0px 0px;
      border: none;
      letter-spacing: 9px;
      text-indent: -5px;
      text-align: right;
      background: transparent;
      background-size: 22px auto;
      color:transparent;
      opacity: 0%;
      height:34px;


    }
    position: relative;

    .falesna-hodnota{
      display: block;
      position:absolute;
      top:0;
      height:36px;
      width:22px;
      border-right: #dddddd 1px solid;
      font-family: Courier, "Courier New";
      font-size: 22px;
      text-align: center;
      background: white;
    }

    &.ro_true{
      .falesna-hodnota{
         background: #eee;
      }
    }

  }

</style>
