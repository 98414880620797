<template>



  <modal name="modal1" :width="850" :min-height="800" :adaptive="true"


         >
    <div class="float-right">

      <button class="btn" @click="expandModal">
        <img src="/img/expand.svg" width="30" />
      </button>

    <button class='btn' @click="$modal.hide('modal1')">
      ❌
    </button>



  </div>
    <div class="p-3">

      <h6 class="my-2">

        <b>Print PDF</b>

      </h6>



      <div class="d-flex ">
        <div class="mr-1 w-50">
          <label class="d-block">{{$t('qrtopdf.seriove_cislo')}}</label>
          <input type="text" v-model="sn1" class="form-control" @keyup="aktivaceResult=''"/>
        </div>
        <div class="mr-1 w-25" >
          <label class="d-block">{{$t('qrtopdf.pocet_stitku')}}</label>
          <input type="number" v-model="pocet_stitku" class="form-control "/>
        </div>
        <div class="mr-1 w-25">
          <label class="d-block">width</label>
            <select v-model="scale" class="form-control">
              <option value="1">35mm</option>
              <option value="0.8571428571428571">30mm</option>
              <option value="0.7142857142857143">25mm</option>
              <option value="0.5714285714285714">20mm</option>
            </select>
        </div>
        <div class="mr-1 w-25">
            <label class="d-block">Format</label>
            <select v-model="out_format" class="form-control">
              <option value="PDF">PDF</option>
              <option value="SVG">SVG</option>
            </select>
        </div>
        <div class="mr-1 w-25">
            <label class="d-block">Template</label>
            <select v-model="svg_template" class="form-control">
              <option value="1">big</option>
              <option value="2">small</option>
            </select>
        </div>




        <div class="ml-auto" style="min-width: 180px;">
          <label class="d-block">&nbsp;</label>
          <button class="btn btn-success " style="width: 180px;" @click="run_kontrola_Generator()" v-if="!isLoading && dups.length==0">{{$t('qrtopdf.activate-generate')}}</button>
          <img src="/img/indicator.gif" v-if="isLoading" class="ml-auto mr-auto d-block"/>
        </div>


      </div>

<!--
      <div class="d-flex my-2">

          <div class="ml-auto form-control border-0 col-3">{{$t('admin.activate-qr-cb')}}:</div>
          <div class="mr-1"><datepicker format="dd.MM.yyyy" v-model="datum" ></datepicker></div>

        <div class="" style="min-width: 180px;">

          <button class="btn btn-success " style="width: 180px;" @click="runActivator()" >{{$t('admin.activate-qr-cb')}}</button>
          <img src="/img/indicator.gif" v-if="isLoading2" class="ml-auto mr-auto d-block"/>
        </div>


      </div>
      -->
      <div v-if="aktivaceResult" class='alert-success alert mt-2'>{{aktivaceResult}}</div>


      <div class='alert-danger alert' v-if="chybaZadani">
        {{ chybaZadani }}
      </div>

      <!-- <div v-html="svgp" style="background: white; padding:5px;display: inline-block; border:1px solid black;"></div>-->

      <div class="row" v-if="dups && dups.length>0">

        <div class="col-md-6">
          <div class="mt-4 alert-danger px-1 py-2">
            <strong class="">{{$t('qrcode.already-activated')}}</strong>
          </div>
          <p v-for="d in dups" :key="`dup${d}`" class="my-1">{{d}}</p>
        </div>
        <div class="col-md-6">
          <div class="mt-4 alert-success px-1 py-2">
            <strong class="">{{$t('qrcode.free-sns')}}</strong>
          </div>
          <p v-for="d in nodups" :key="`nodup${d}`" class="my-1">{{d}}</p>
          <button class="btn btn-success mt-3 w-100"  @click="runGenerator()" v-if="!isLoading">{{$t('qrcode.activate-nodup')}}</button>
          <button class="btn btn-secondary mt-3 w-100"  @click="cancelGenerator()" v-if="!isLoading">{{$t('qrcode.cancel-activation')}}</button>
        </div>



      </div>

      <div  v-if="pdflink && !isLoading">
        <div class="my-2 float-right">
          <a :href="pdflink" target="_blank" style="width: 150px;" class="btn btn-warning">{{$t('qrcode.Download_PDF')}}</a>
        </div>
        <div id="column-2" style="" >
          <iframe id="pdf-file" style="width: 100%; min-height:400px;height:600px"  :src="pdflink"></iframe>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>

import svg1 from '../utils/svg-tpl';
import qrcodes from '../utils/qrcodes'
const axios = require("axios");
//import Datepicker from 'vuejs-datepicker';

const moment = require('moment');
require('moment/locale/cs');
moment.locale('cs');
const JSZip = require("jszip");
import { saveAs } from 'file-saver';

export default {
    name: "QrToPdf",
    props: ['kod'],
    //components: { Datepicker },
    watch:{
      kod(){
        console.log('chasnge kod() sn1',this.kod.params[20])
        this.sn1 = this.kod.params[20]
      },
      'kod.params'(){
        console.log('chasnge kod.params sn1',this.kod.params[20])
        this.sn1 = this.kod.params[20]

        let c1=parseInt(this.sn1)
        if (isNaN(c1)){
          this.chybaZadani='wrong number format'
        }
        this.pdflink=''
        this.isLoading=false
      },
      sn1(){
        console.log('chasnge sn1() sn1',this.sn1)
        this.chybaZadani=false
        let c1=parseInt(this.sn1)
        if (isNaN(c1)){
          this.chybaZadani='wrong number format in production number'
        }
        //this.aktivaceResult='sn#'
      },
      pocet_stitku(){
        this.chybaZadani=false
        let c=parseInt(this.pocet_stitku)
        if (isNaN(c)){
          this.chybaZadani='wrong number format'
        }
      }
    },

  data(){
    return{
      svg_template:1,

      modal_top:10,
      modal_width:800,
      modal_height:800,

      svgp:'',
      pdfdoc:null,
      pdflink:"",
      sn1:'',
      sn2:'',
      isLoading:false,
      isLoading2:false,
      scale:1,
      out_format:'PDF',
      chybaZadani:false,
      pocet_stitku:10,
      datum: moment().toDate(),
      aktivate_qr:false,
      aktivaceResult:'0',
      dups:[],
      nodups:[]
    }
  },
  mounted() {
    this.aktivaceResult=''
  },
  methods:{


    winResizeEventHandler(){

      console.log('Window height',window.screen.height);
      console.log('Inner height',window.innerHeight);

      var h = window.innerHeight

      var el=document.getElementsByClassName('v--modal-box')

      if (el){
        var div = el[0]
        div.style.left=0
        div.style.width='100%'
        div.style.top=0
        div.style.height = h+'px'
      }
      //this.$modal.show('modal1', {width:'100%'})

    },

    expandModal(){

      this.winResizeEventHandler()

      window.addEventListener("resize", this.winResizeEventHandler);
    },




    getSn(kode){
      var s = kode.toString().substring(80,90)
      return parseInt(s.replace(/_/g, ''))
    },

    refreshInitial(){
      console.log('refr initi', this.sn1,'kk',this.getSn(this.kod.code), this.kod.code)
      if (!this.sn1 || this.sn1=="") this.sn1  =  this.getSn(this.kod.code)
      this.dups=[]
      this.nodups=[]
      this.isLoading2=true;
      this.aktivaceResult=''
      this.pdfdoc=null
      this.pdflink=""
      this.chybaZadani=false
      let c1=parseInt(this.sn1)
      if (isNaN(c1)){
          this.chybaZadani=`wrong number format in production number ${this.sn1}`
      }



    },
    cancelGenerator(){
      this.dups=[]
      this.nodups=[]
      this.$parent.$modal.hide('modal1')

    },
    run_kontrola_Generator(){
      var c1, c2
      try{
        c1= parseInt(this.sn1)
        c2= parseInt(this.pocet_stitku)
      }catch (e) {
        this.chybaZadani=e
      }

      if (isNaN(c1) || isNaN(c2) || c1==0){
        this.chybaZadani = 'nesmyslna cisla'
        this.isLoading=false
        return
      }


      axios.post(process.env.VUE_APP_API_ROOT_URL+'/manufacture/qr-test', {sn: c1, count: c2, template_id: this.kod.id}).then(response => {
        this.isLoading2=false;
        if (response.status==200 || response.status==204){
              this.dups = response.data.dups
              this.nodups = response.data.nodups
              if (response.data.dups.length==0 && response.data.nodups.length>0){
                this.runGenerator()
              }
        }
      }).catch(e =>{
        console.error( e)
        this.isLoading2=false;
      })
    },

    runActivator(){
       this.runActivatorOrGenerator(true, false)
    },

    async runGenerator(){
      this.dups=[]
      return await this.runActivatorOrGenerator(true, true)
    },
    async runActivatorOrGenerator(act, gen){
      var c1, c2
      try{
        c1= parseInt(this.sn1)
        c2= parseInt(this.pocet_stitku)
      }catch (e) {
        this.chybaZadani=e
      }

      if (isNaN(c1) || isNaN(c2) || c1==0){
        this.chybaZadani = 'nesmyslna cisla'
        this.isLoading=false
        return
      }



      if (c1>0 && c2>0) {
        var codes=[];
        //for (var i = 0; i < c2; i++){
        //  codes.push( this.copycode(this.kod.params, parseInt(c1)+i) )
        //}
        for (var i=0; i < this.nodups.length; i++){
          let sn=this.nodups[i]
          codes.push( this.copycode(this.kod.params, parseInt(sn)) )
        }

        var pages = await svg1.generujStranky(codes, this.scale, this.svg_template)
        if (gen) {
          this.isLoading=true;
          if (this.out_format=='SVG') this.createPackSVG(pages)
          if (this.out_format=='PDF') this.createPdf(pages)
        }

        if (act) {
          this.isLoading2=true;
          this.aktivaceResult='1'
          axios.post(process.env.VUE_APP_API_ROOT_URL+'/manufacture/qractivate', {
            sn: c1,
            count: c2,
            date: this.datum,
            codes: codes,
            template_id: this.kod.id
          }).then(response => {
            console.log('aktivace', response.status, response.data)
            this.isLoading2=false;
            if (response.status==200 || response.status==204){
              this.aktivaceResult = this.$t('qrcode.activated-ok')

              let nsn = this.nodups[this.nodups.length-1]
              console.log('NSN RES', nsn)
              if (nsn>0){
                this.sn1 = nsn+1
                this.$parent.kod_for_print.last_sn = nsn
              }
            }
          }).catch(e =>{
            console.error( e)
            this.isLoading2=false;
          })
        }

      }
    },

      copycode(params, sn){
        var arr=[];
        for(var i in params){
          let v=params[i]
          if (i==20) v = sn
          arr.push(v)
        }
        return qrcodes.codeAsString(arr)
      },

      ons(){
        console.log('ons')
      },

    createPackSVG(pages){
      var zip = new JSZip();
      for(var n in pages) {
        zip.file(`page-${n+1}.svg`, pages[n]);
      }
      var that = this
      zip.generateAsync({type: "blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9
        }}).then(function(content) {
            // see FileSaver.js

            saveAs(content, `pages-svg-${that.sn1}.zip`,{ autoBom: true });
            that.isLoading = false
      });
    },

    createPdf(svgPages) {
      var form = new FormData();
      for (var i = 0; i < svgPages.length; i++) {
        const svg = svgPages[i]
        //console.log('post push svg', svg)
        form.append(`pages[${i}]`, svg);
      }


      const url = "https://pdfkit.mybeedroid.eu/run2"
      //const url = "http://localhost:30002/run2"
      axios.post(url, form).then(response => {

        this.pdflink = response.data.url
        this.isLoading = false
      }).catch(err => {
        console.error(err)
        this.isLoading = false
        this.dups=[]
        this.nodups=[]
      })

    }



  }
}
</script>

<style scoped lang="scss">
label{
  font-size: 85%;
  height:10px;
}




</style>
